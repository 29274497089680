<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }" v-if="currentCategory!=null" >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Content
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Content Title"
            rules="required"
          >
            <b-form-group
              label="Content Title"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="currentCategory.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Content Title"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

       

        

          
          <validation-provider
            #default="validationContext"
            name="Key"
            rules="required"
          >  <b-card-text class="small text-muted">
      Description for the area. You may add defitinion where to use.
      </b-card-text>
            <b-form-group
              label="Content Description"
              label-for="Description"

            >
              <b-form-input
                id="Description"
                v-model="currentCategory.description"
                :state="getValidationState(validationContext)"
                   placeholder="Description "
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

             <!-- Username -->
         
          
             
              <quill-editor
     
     
     height="100"
    :options="snowOption"
     :content="currentCategory.content" 
  @change="onEditorChange($event)"
    @blur="onEditorBlur($event)"
  @focus="onEditorFocus($event)"
  @ready="onEditorReady($event)"
  />
              
 

          <validation-provider
            #default="validationContext"
            name="Key"
            rules="required"
          >  <b-card-text class="small text-muted">
       The Keys should be unique. Keys are used for named API calls.
      </b-card-text>
            <b-form-group
              label="Content Key"
              label-for="ContentKey"

            >
              <b-form-input
                id="ContentKey"
                v-model="currentCategory.content_key"
                :state="getValidationState(validationContext)"
                   placeholder="Content Key"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
         
           

          

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="currentCategory==null|| currentCategory.id==null"
            >
                     Add
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updatecategory"
            v-if="currentCategory!=null&&currentCategory.id!=null"
            >
                      Update 
            </b-button>

              <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="5>3&&currentCategory!=null&& currentCategory.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DepartmentPicker from '../components/DepartmentPicker.vue'

import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods:{
    onEditorBlur(quill) {
    //    console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
     //   console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
   //     console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
   //     console.log('editor change!', quill, html, text)
    // test

        this.currentCategory.content= html
      },
    onDepartmentSelectionChanged(payload)
    {
      console.log("currentCategory selected");
      console.log(payload);
      this.currentCategory.upperCategory=payload.id;
this.currentCategory.Parent=payload ;
    },
     selectionChanged(selectedGroup){
       // console.log(selectedGroup);

       if (selectedGroup==null||selectedGroup==undefined)
       {
   this.currentCategory.upperCategory = null;
       }else
       {
   this.currentCategory.upperCategory = selectedGroup.id
       }
   
    },
    deletecategory(){
      this.currentCategory.deleted=true;

      this.$store.dispatch('apps-blogcategory/updateblogtag', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },
    updatecategory(){
     console.log("sent data");
     console.log(this.currentCategory);

      this.$store.dispatch('apps-blogcategory/updateblogtag', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    },

    submitForm(){
 
    console.log(this.currentCategory);
    
       this.$store.dispatch('apps-blogcategory/createDepartment', this.currentCategory)
        .then((result) => {
          console.log(result);
          
          this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
        });
    }
  },
  
  watch: {
     categorydata:   {
        handler:function (n, o)   {
          console.log('new client: ', n);

                  if (n==null||n==undefined)
                  {
                    this.currentCategory={
                      id:null,
                      name:null,
                      description:null,
                       upperCategory: null,
                      Parent:null
                    }
                  } else
                  { 
                    console.log('new client: ', n.selectedCategory);
                  this.currentCategory = n.selectedCategory;
                  }
        

        },
        deep: true
    }
    
},
  
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      currentCategory :null,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
        
           
        [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      }
    }
  },
   inject: ['categorydata'],
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      upperCategory: '',
      description: '',
      parent:null
       
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('apps-blogcategory/createDepartment', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
 
@import '@core/scss/vue/libs/quill.scss';

.ql-editor{
    min-height:100px;
}
</style>
